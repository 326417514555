import { useMemo } from 'react';
import { DropdownData } from 'interfaces';

interface UseDropdownData {
	dropdownData: DropdownData[];
}

const useDropdownData = <T,>(
	data: T[],
	valueKey: string,
	labelKey: string
): UseDropdownData => {
	const dropdownData = useMemo(() => {
		if (!data) return [];

		return data.map(d => ({ value: d[valueKey], label: d[labelKey] }));
	}, [data, labelKey, valueKey]);

	return {
		dropdownData,
	};
};

export default useDropdownData;
