import { useAbortSWR } from 'hooks/use-abort-swr';
import { ConfigInterface, keyInterface, responseInterface } from 'swr';
import { fetcherWithCancel } from 'utils/api';

export const useApi = <Data,>(
	key?: keyInterface,
	options?: ConfigInterface<Data, Error>,
	retrieveData = true
): responseInterface<Data, Error> & {
	abort: (text?: string) => void;
	isLoading?: boolean;
} => {
	const result = useAbortSWR<Data, Error>(
		retrieveData && key,
		fetcherWithCancel,
		options
	);

	return result;
};

export default useApi;
