import { useState, useEffect, useCallback } from 'react';
import { command, UploadFile, PrepareUpload } from 'utils/api';
import {
	BatchValidation,
	AsyncResponse,
	UploadDetailsDto,
	InitializeUploadDetails,
} from 'interfaces/api';
import { isTimestampEligibleForPolling } from 'utils/time-helpers/time-helpers';
import { Status } from 'interfaces/api/enums/status';
import { UploadType } from 'types/upload-type';
import splitFiles from 'utils/file-chunks/file-chunks';
import { statusToPoll } from 'utils/status-helpers/status-helpers';
import useApi from './use-api/use-api';

const UPDATE_INTERVAL = 5000;
const POLLING_STATUS = statusToPoll;
const POLLING_MAX_AGE_THRESHOLD = 60 * 20 * 1000;
const UPLOAD_CHUNK_SIZE = 4000000;

type State = 'isUploading' | 'isDeleting' | 'isImporting';

interface UseValidatedFiles extends AsyncResponse<BatchValidation> {
	files: BatchValidation[];
	validateAndUpload: (
		formData: FormData,
		fileFormatId: number
	) => Promise<void>;
	deleteValidation: (id: number) => Promise<void>;
	importPortfolio: (id: number, portfolioName: string) => Promise<void>;
	state: State;
	error: Error;
	resetError: () => void;
}

const useValidatedFiles = (
	dcaItemId: number,
	type: UploadType
): UseValidatedFiles => {
	const BASE_URL = `/dca/${dcaItemId}/validation`;
	const [shouldPoll, setShouldPoll] = useState(false);
	const [state, setState] = useState<State | null>(null);
	const [submitError, setSubmitError] = useState<Error | null>(null);

	const {
		data,
		isValidating: isLoading,
		revalidate,
		mutate,
	} = useApi<BatchValidation[]>(
		dcaItemId ? `${BASE_URL}/latest?type=${type}` : null,
		{
			refreshInterval: shouldPoll ? UPDATE_INTERVAL : 0,
		}
	);

	useEffect(() => {
		const shouldPollBatchStatus = data?.some(
			s =>
				POLLING_STATUS.includes(s.status) &&
				isTimestampEligibleForPolling(s.registeredAt, POLLING_MAX_AGE_THRESHOLD)
		);

		const shouldPollFileStatus = data
			?.reduce((acc, val) => acc.concat(val.fileValidations), [])
			.some(
				f =>
					POLLING_STATUS.includes(f.status) &&
					isTimestampEligibleForPolling(
						f.registeredAt,
						POLLING_MAX_AGE_THRESHOLD
					)
			);

		setShouldPoll(shouldPollBatchStatus || shouldPollFileStatus);
	}, [data]);

	const prepareFormData = useCallback(
		(formData: FormData): InitializeUploadDetails[] => {
			return [...formData.values()].reduce((all, file: FormDataEntryValue) => {
				const details = file as File;
				all.push({ name: details.name, fileSize: details.size, file: file });
				return all;
			}, []);
		},
		[]
	);

	const initializeUpload = useCallback(
		async (fileFormatId: number, fileMetaData): Promise<UploadDetailsDto> => {
			return PrepareUpload(`${BASE_URL}/prepare`, fileFormatId, type, {
				files: fileMetaData.map(file => {
					return {
						name: file.name,
						fileSize: file.fileSize,
					};
				}),
			});
		},
		[BASE_URL, type]
	);

	const triggerValidation = useCallback(
		async (validationId: number) => {
			await command(`${BASE_URL}/${validationId}/start`, null, 'POST');
		},
		[BASE_URL]
	);

	const deleteValidation = useCallback(
		async (id: number) => {
			try {
				setState('isDeleting');
				setSubmitError(null);
				await command(`${BASE_URL}/${id}`, null, 'DELETE');
				revalidate();
			} catch (error) {
				if (error instanceof Error) {
					setSubmitError(error);
				}
			} finally {
				setState(null);
			}
		},
		[BASE_URL, revalidate]
	);

	const importPortfolio = useCallback(
		async (id: number, portfolioName: string) => {
			try {
				setState('isImporting');
				setSubmitError(null);
				await command(
					`${BASE_URL}/${id}/import?batchName=${portfolioName}`,
					null,
					'POST'
				);
				const dataCopy = [...data];
				const itemIndex = dataCopy.findIndex(f => f.id === id);
				dataCopy.splice(itemIndex, 1, {
					...dataCopy[itemIndex],
					status: Status.PortfolioImportNew,
				});
				mutate([...dataCopy]);
			} catch (error) {
				if (error instanceof Error) {
					setSubmitError(error);
				}
			} finally {
				setState(null);
			}
		},
		[BASE_URL, data, mutate]
	);

	const validateAndUpload = useCallback(
		async (formData: FormData, fileFormatId: number) => {
			try {
				setState('isUploading');
				setSubmitError(null);
				const data = prepareFormData(formData);
				const uploadDetails = await initializeUpload(fileFormatId, data);
				const chunks = splitFiles(uploadDetails, data, UPLOAD_CHUNK_SIZE);
				await Promise.all(
					chunks.map(chunk =>
						UploadFile(
							chunk.uri,
							chunk.data,
							chunk.batchSize,
							chunk.bytesFrom,
							chunk.bytesTo
						)
					)
				);
				await triggerValidation(uploadDetails.validationId);
				revalidate();
			} catch (error) {
				if (error instanceof Error) {
					setSubmitError(error);
				}
			} finally {
				setState(null);
			}
		},
		[initializeUpload, prepareFormData, revalidate, triggerValidation]
	);

	const resetError = useCallback(() => {
		setSubmitError(null);
	}, []);

	return {
		files: data || [],
		validateAndUpload,
		deleteValidation,
		importPortfolio,
		isLoading,
		state,
		error: submitError,
		resetError,
	};
};

export default useValidatedFiles;
