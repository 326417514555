import React from 'react';
import { Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { SWRConfig } from 'swr';
import { fetcher } from './utils/api';
import useAuthProvider from './hooks/use-auth-provider';
import ErrorBoundaryFallback from './components/error-boundary-fallback';
import LoginPage from './pages/login';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { fileRoutes } from 'routes';
import BaseRedirect from 'pages/base-redirect';
import { LicenseManager } from 'ag-grid-enterprise';
import { useMsal } from '@azure/msal-react';
import { useOriginalPathRedirect } from 'pages/login/hooks/use-original-path-redirect';
import ClientSettingsProvider from 'contexts/client-settings-provider';
import {
	DuiFullscreenLoader,
	DuiGlobalStyles,
	DuiSnackbarProvider,
	DuiTheme,
} from '@dignisia/dui';
import MuiGlobalStyles from 'themes/mui-global-styles';
import AgGridGlobalStyles from 'themes/ag-grid-global-styles';

const App: React.FC = () => {
	//License key for AG Grid
	LicenseManager.setLicenseKey(
		'CompanyName=Dignisia AB,LicensedApplication=Dignisia,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-029265,SupportServicesEnd=14_June_2023_[v2]_MTY4NjY5NzIwMDAwMA==cd79931938b35e2a9eb1d862853fbe8f'
	);
	const { inProgress } = useMsal();

	const { isAuthenticated, account } = useAuthProvider();
	const location = useLocation();

	useOriginalPathRedirect();

	return (
		<SWRConfig
			value={{
				fetcher,
				revalidateOnFocus: false,
			}}
		>
			<MuiGlobalStyles />
			<AgGridGlobalStyles />
			<DuiSnackbarProvider>
				<ClientSettingsProvider>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={DuiTheme}>
							<DuiGlobalStyles />
							<Suspense fallback={<DuiFullscreenLoader />}>
								<Switch>
									<Route exact path="/login">
										<LoginPage />
									</Route>
									{!isAuthenticated && inProgress === 'none' && !account && (
										<Redirect
											to={{
												pathname: `/login`,
												search: location.search,
												state: {
													from: location,
												},
											}}
										/>
									)}
									<ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
										{fileRoutes.map((route, index) => (
											<Route
												key={index}
												name={route.name}
												exact={route.exact}
												path={route.path}
												render={() => <route.component />}
											/>
										))}
										<Route
											name="Redirect"
											exact
											path="/"
											component={BaseRedirect}
										/>
									</ErrorBoundary>
								</Switch>
							</Suspense>
						</ThemeProvider>
					</StyledEngineProvider>
				</ClientSettingsProvider>
			</DuiSnackbarProvider>
		</SWRConfig>
	);
};

export default App;
