/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import { UploadDetailsDto } from 'interfaces/api';
import { UploadType } from 'types/upload-type';
import { msalInstance, MSAL_SCOPES } from './auth';
import { request } from './axios';
import { isTokenValid } from './token';
import config from 'api/config';

const isDev = Boolean(config?.env === 'development');

export const getIdToken = async () => {
	const accounts = msalInstance.getAllAccounts();

	if (accounts.length > 0) {
		const request = {
			scopes: MSAL_SCOPES,
			account: accounts[0],
		};
		const idToken = await msalInstance
			.acquireTokenSilent(request)
			.then(response => {
				return response.idToken;
			})
			.catch(error => {
				// Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
				console.log('', error);
				return null;
			});

		return idToken;
	}

	return null;
};

export const addSearchParams = (uri: string) => {
	const encodedUri = uri.startsWith('http')
		? new URL(uri)
		: new URL(`${config.apiUrl}${uri}`);
	// Add username to request to ensure that the API works locally
	if (
		config.apiUrl.includes('localhost') ||
		config.apiUrl.endsWith(':7071/api')
	) {
		encodedUri.searchParams.append('userName', process.env.REACT_APP_USERNAME);
	}
	return encodedUri;
};

export const fetcher = async (...args) => {
	const token = await getIdToken();
	const url = args && addSearchParams(args[0]);
	const response = await fetch(url.toString(), {
		method: 'GET',
		headers: {
			accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	return await response.json();
};

export const PrepareUpload = async (
	url: string,
	fileFormatId: number,
	type: UploadType,
	data?: unknown
): Promise<UploadDetailsDto> => {
	const token = await getIdToken();
	const encodedUri = addSearchParams(url);
	encodedUri.searchParams.append('fileFormatId', fileFormatId.toString());
	encodedUri.searchParams.append('type', type);
	const options = {
		method: 'POST',
		body: typeof data !== 'undefined' && JSON.stringify(data),
		headers: createHeader(token, url),
	};

	const response = await fetch(encodedUri.toString(), options);
	if (!response.ok) {
		throw new Error('Could not prepare upload');
	}
	return (await response.json()) as UploadDetailsDto;
};

export const UploadFile = async (
	url: string,
	formData: Blob,
	batchSize: number,
	bytesFrom: number,
	bytesTo: number
): Promise<Response> => {
	const options = {
		method: 'PUT',
		body: formData,
		headers: {
			'x-ms-write': 'update',
			'x-ms-version': '2015-02-21',
			'x-ms-range': `bytes=${bytesFrom}-${bytesTo}`,
		},
	};

	const response = await fetch(url, options);

	if (!response.ok) {
		throw new Error(await response.text());
	} else {
		return response;
	}
};

export enum Methods {
	GET,
	POST,
	PUT,
	DELETE,
}

export const commandWithJsonResponse = async <T>(
	uri: string,
	body: any,
	method = 'POST'
) => {
	// eslint-disable-next-line no-useless-catch
	try {
		const response = await command(uri, body, method);
		return await response.json().then(response => response as T);
	} catch (err) {
		throw err;
	}
};

const createHeader = (token: string, url: string) => {
	const header = {
		accept: 'application/json',
		Authorization: `Bearer ${token}`,
	};

	return header;
};

export const checkForUnauthorizedError = (token: string) => {
	if (!isTokenValid(token)) {
		// If an API call returns 401 and the token is expired -> logout the user
		msalInstance.logoutRedirect();
	}
};

export const command = async (uri: string, body: any, method = 'POST') => {
	const token = await getIdToken();
	const encodedUri = addSearchParams(uri);

	const options: any = {
		method,
		headers: createHeader(token, uri),
	};

	if (typeof body !== 'undefined' && body !== null) {
		options.body = JSON.stringify(body);
		if (typeof body !== 'string') {
			options.headers = {
				...options.headers,
				'Content-Type': 'application/json',
			};
		}
	}

	const res = await fetch(`${encodedUri}`, options);

	if (res.status >= 400 && res.status <= 500) {
		if (res.status === 401 || res.status === 403) {
			checkForUnauthorizedError(token);
		} else {
			const ex = await res.json();
			throw new Error(
				(isDev && ex && ex.errorMessage) || 'An error has occurred'
			);
		}
	}
	return res;
};

const UserFriendlyError = 5;

export const fetcherWithCancel = async (
	axiosConfig: AxiosRequestConfig = {},
	...args: [endpoint: string, searchParams: Record<string, string> | undefined]
) => {
	const [endpoint, searchParams] = args;

	const token = await getIdToken();

	const encodedUri = args && addSearchParams(endpoint);

	if (searchParams && Array.isArray(searchParams)) {
		searchParams
			.filter(arg => Boolean(arg.val))
			.map(arg => encodedUri.searchParams.append(arg.name, arg.val));
	}

	const res = await request
		.get(`${encodedUri}`, {
			method: 'GET',
			headers: createHeader(token, endpoint),
			cancelToken: axiosConfig?.cancelToken,
		})
		.catch(error => {
			if (error?.response?.data?.resultStatus === UserFriendlyError) {
				return Promise.reject(error?.response?.data?.errorMessage);
			}

			if (error && error.toString().includes('404')) {
				return Promise.reject('Endpoint was not found');
			}
			if (axios.isCancel(error) && error instanceof Error) {
				console.log('REJECTED', error.message);
			} else {
				// handle error
			}
		});

	if (res && res.status >= 400 && res.status <= 500) {
		if (res.status === 401 || res.status === 403) {
			checkForUnauthorizedError(token);
		} else {
			const ex = await res.data;
			throw new Error(isDev && ex && ex.errorMessage);
		}
	}
	if (res) {
		return await res.data;
	}
};
