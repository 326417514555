import { command } from 'utils/api';
import { AsyncResponse, AvailableClient, Client } from 'interfaces/api';
import useAuthProvider from 'hooks/use-auth-provider';
import useApi from './use-api/use-api';

export interface UseClientProps extends AsyncResponse<Client[]> {
	clients: Client[];
	selectedClientId?: number;
	selectedClient?: Client;
	updateCurrentClient: (clientId: number) => Promise<void>;
}

const baseUrl = '/users/clients';

const useClients = (): UseClientProps => {
	const { isAuthenticated } = useAuthProvider();

	const { data, error, isValidating, mutate } = useApi<AvailableClient>(
		isAuthenticated ? baseUrl : null
	);

	const updateCurrentClient = async (clientId: number) => {
		await command(`${baseUrl}/${clientId}`, null, 'PUT');
		mutate();
	};

	return {
		clients: data ? data.clients : [],
		isLoading: isValidating,
		error,
		selectedClientId: data?.selectedClientId,
		selectedClient: data?.clients?.find(x => x.id === data.selectedClientId),
		updateCurrentClient,
	};
};

export default useClients;
