export enum Status {
	New = 0,
	Started = 1,
	ParsingStarted = 2,
	Finished = 3,
	FinishedWithErrors = 4,
	Failed = 5,
	FinishedWithWarnings = 6,
	PortfolioImportNew = 9,
	PortfolioImportStarted = 10,
	PortfolioImportStaged = 11,
	PortfolioImportDone = 12,
	PortfolioImportFinishedWithErrors = 13,
}
