import { DuiColorVariances } from '@dignisia/dui';
import { Status } from 'interfaces/api/enums/status';

const StatusMessages = new Map<number, string>([
	[Status.New, 'New'],
	[Status.Started, 'Validation started'],
	[Status.ParsingStarted, 'Validation started'],
	[Status.Finished, 'Validation finished successfully'],
	[Status.FinishedWithErrors, 'Validation finished with errors'],
	[Status.Failed, 'Validation failed'],
	[Status.FinishedWithWarnings, 'Validation finished with warnings'],
	[Status.PortfolioImportNew, 'The portfolio import started'],
	[Status.PortfolioImportStarted, 'The portfolio import started'],
	[Status.PortfolioImportStaged, 'The portfolio import started'],
	[Status.PortfolioImportDone, 'The portfolio imported successfully'],
	[Status.PortfolioImportFinishedWithErrors, 'The portfolio import failed'],
]);

const StatusColors = new Map<number, string>([
	[Status.New, 'GREY_250'],
	[Status.Started, 'THUNDER_200'],
	[Status.ParsingStarted, 'THUNDER_200'],
	[Status.Finished, 'GREEN_APPLE_500'],
	[Status.FinishedWithErrors, 'ERROR_RED_LIGHT'],
	[Status.Failed, 'ERROR_RED_LIGHT'],
	[Status.FinishedWithWarnings, 'WARNING_YELLOW'],
	[Status.PortfolioImportNew, 'GREY_250'],
	[Status.PortfolioImportStarted, 'THUNDER_200'],
	[Status.PortfolioImportStaged, 'GREEN_APPLE_500'],
	[Status.PortfolioImportDone, 'GREEN_APPLE_500'],
	[Status.PortfolioImportFinishedWithErrors, 'ERROR_RED_LIGHT'],
]);

export const statusToPoll = [
	Status.New,
	Status.Started,
	Status.ParsingStarted,
	Status.PortfolioImportNew,
	Status.PortfolioImportStarted,
	Status.PortfolioImportStaged,
];
export const statusWithReport = [
	Status.FinishedWithErrors,
	Status.FinishedWithWarnings,
];

export const statusWithoutReconciliationReport = [
	Status.New,
	Status.Started,
	Status.ParsingStarted,
];

export const isDeleteAvailable = (status: number) => {
	return [
		Status.New,
		Status.Finished,
		Status.FinishedWithErrors,
		Status.Failed,
		Status.FinishedWithWarnings,
		Status.PortfolioImportDone,
		Status.PortfolioImportFinishedWithErrors,
		Status.Started,
	].includes(status);
};

export const isRunning = (status: number) => {
	return [Status.New, Status.Started, Status.ParsingStarted].includes(status);
};

export const isFinishedSuccessfully = (status: number) => {
	return [Status.Finished, Status.Started, Status.PortfolioImportDone].includes(
		status
	);
};

export const isImportAvailable = (status: number) => {
	return status !== Status.PortfolioImportDone;
};

export const isStatusSuccessful = (status: number) => {
	return status === Status.Finished;
};

export const getDisplayNameFromStatus = (status: number) => {
	return StatusMessages.get(status);
};

export const getColorFromStatus = (status: number) => {
	return StatusColors.get(status) as keyof DuiColorVariances;
};
