import { MouseEvent } from 'react';

/**
 * Cache version if you need to bust the cache for all clients
 */
export const CACHE_VERSION = 2;

export const clearCacheKeys = async (): Promise<void> => {
	if ('serviceWorker' in navigator) {
		await caches.keys().then(cacheNames => {
			cacheNames.forEach(cacheName => {
				caches.delete(cacheName);
			});
		});
	}
};

export const clearCacheKeysAndLocalStorage = async (
	event: MouseEvent<HTMLAnchorElement>
): Promise<void> => {
	event?.preventDefault();
	await clearCacheKeys();
	localStorage.clear();
	location.reload();
};
