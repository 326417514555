import React from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorBoundaryFallback: React.FC<FallbackProps> = ({ error }) => {
	const isDevelopment = process.env.NODE_ENV === 'development';
	if (error && isDevelopment) {
		return <span>{error.message}</span>;
	}

	return <span>Something went wrong!</span>;
};

export default ErrorBoundaryFallback;
