import React, { useEffect } from 'react';

import useAuthProvider from 'hooks/use-auth-provider';
import { DuiButton, LogoComponent } from '@dignisia/dui';
import { useSetOriginalPathRedirect } from './hooks/use-original-path-redirect';
import styled from 'styled-components';
import loginpage_background from 'images/loginpage_background.jpg';

const LoginPage: React.FC = () => {
	const { onSignIn, progress } = useAuthProvider();

	useSetOriginalPathRedirect();

	useEffect(() => {
		const listener = event => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				event.preventDefault();
				onSignIn();
			}
		};
		document.addEventListener('keydown', listener);
		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, [onSignIn]);

	return (
		<LoginContainer>
			<LoginLogoContainer>
				<LogoComponent />
			</LoginLogoContainer>
			<div>
				<DuiButton
					buttonStyling="primary"
					disabled={progress === 'login'}
					onClick={onSignIn}
				>
					{progress !== 'login' ? 'Login' : 'You are currently logging in'}
				</DuiButton>
			</div>
		</LoginContainer>
	);
};

const LoginContainer = styled.div`
	background: url('${loginpage_background}') no-repeat center;
	background-size: cover;
	text-align: center;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
`;

const LoginLogoContainer = styled.div`
	display: inline-block;
	margin-top: 6.25em;
	margin-bottom: 3.15em;
`;

export default LoginPage;
