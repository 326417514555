const config: Config = window.MyConfig;

export const isDev = Boolean(config?.env === 'development');

const configFallback: Config = {
	reverseProxyUrl: '',
	apiUrl: 'http://localhost:7071/api',
	dataProviderServiceUrl: 'http://localhost:7072/api',
	logoutRedirectUrl: 'http://localhost:3000',
	msalTenantId: '714dd6c8-f309-49a3-a4f2-47dc5e777b36',
	msalClientId: '687b7718-7dfd-4c58-b8eb-fb0b9fd3202c',
	sftpIp: 'dignisia-sftp-dev.westeurope.azurecontainer.io',
	sftpPort: '22',
	env: 'development',
	title: 'Dignisia local',
	applicationInsights: undefined,
};

const isDevelopment = process.env.NODE_ENV === 'development';

export default isDevelopment
	? {
			...config,
			reverseProxyUrl:
				process.env.REACT_APP_REVERSE_PROXY_URL ??
				configFallback.reverseProxyUrl,
			apiUrl: process.env.REACT_APP_APIURL ?? configFallback.apiUrl,
			dataProviderServiceUrl: configFallback.dataProviderServiceUrl,
			logoutRedirectUrl:
				process.env.REACT_APP_LOGOUTREDIRECTURL ??
				configFallback.logoutRedirectUrl,
			msalTenantId:
				process.env.REACT_APP_TENANTID ?? configFallback.msalTenantId,
			msalClientId:
				process.env.REACT_APP_CLIENTID ?? configFallback.msalClientId,
			sftpIp: process.env.REACT_APP_SFTP_IP ?? configFallback.sftpIp,
			sftpPort: process.env.REACT_APP_SFTP_PORT ?? configFallback.sftpPort,
			env: configFallback.env,
			title: configFallback.title,
	  }
	: config;
