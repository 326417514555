import { DuiColors } from '@dignisia/dui';
import { createGlobalStyle } from 'styled-components';

const MuiGlobalStyles = createGlobalStyle`
    .MuiStepIcon-root.MuiStepIcon-completed {
        color:${DuiColors.PACIFIC_600};
    }
    .MuiStepIcon-root.MuiStepIcon-active {
        color:${DuiColors.PACIFIC_600};
    }
    .MuiStepContent-last{
        padding-left: 0px;
        margin-left: 0px;
    }
`;

export default MuiGlobalStyles;
