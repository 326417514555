import { AsyncResponse, PreValidationFile } from 'interfaces/api';
import { useEffect, useState } from 'react';
import useApi from './use-api/use-api';

interface UsePreValidation extends AsyncResponse<PreValidationFile> {
	files: PreValidationFile[];
	unknownFiles: string[];
	batchMessage: string[];
	resetUnknownFiles: () => void;
}

const usePreValidation = (
	dcaItemId: number,
	fileFormatId: number,
	fileNames: string[]
): UsePreValidation => {
	const {
		data,
		error,
		isValidating: isLoading,
	} = useApi<UsePreValidation>(
		dcaItemId && fileNames && fileFormatId
			? `/dca/${dcaItemId}/prevalidation/${fileFormatId}?fileNames=${fileNames.join(
					','
			  )}`
			: null,
		{}
	);
	const [currentUnknownFiles, setCurrentUnknownFiles] = useState(
		data?.unknownFiles
	);

	const resetUnknownFiles = () => {
		setCurrentUnknownFiles([]);
	};

	useEffect(() => {
		if (data?.unknownFiles.length) {
			setCurrentUnknownFiles(data?.unknownFiles);
		}
	}, [data]);

	return {
		files: data?.files || [],
		unknownFiles: currentUnknownFiles,
		batchMessage: data?.batchMessage || [],
		isLoading,
		error: error,
		resetUnknownFiles,
	};
};

export default usePreValidation;
