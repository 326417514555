import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './app';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from 'utils/auth';
import {
	ExtendedStringifyOptions,
	QueryParamProvider,
	transformSearchStringJsonSafe,
} from 'use-query-params';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import config from 'api/config';

const root = createRoot(document.getElementById('root'));

const initialize = () => {
	const isDevelopment = process.env.NODE_ENV === 'development';

	if (!config?.applicationInsights || isDevelopment) {
		return;
	}

	const appInsights = new ApplicationInsights({
		config: {
			connectionString: config.applicationInsights,
		},
	});
	appInsights.loadAppInsights();
};
initialize();

export const queryStringifyOptions: ExtendedStringifyOptions = {
	transformSearchString: transformSearchStringJsonSafe,
};
root.render(
	<BrowserRouter>
		<MsalProvider instance={msalInstance}>
			<QueryParamProvider
				ReactRouterRoute={Route}
				stringifyOptions={queryStringifyOptions}
			>
				<App />
			</QueryParamProvider>
		</MsalProvider>
	</BrowserRouter>
);
