import axios from 'axios';
import { useMemo } from 'react';
import useSWR, { keyInterface } from 'swr';
import { ConfigInterface, fetcherFn, responseInterface } from 'swr/dist/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAbortSWR<Data = any, Error = any>(
	key: keyInterface,
	fn?: fetcherFn<Data>,
	options?: ConfigInterface<Data, Error>
): responseInterface<Data, Error> & {
	abort: (text?: string) => void;
	isLoading?: boolean;
} {
	const CancelToken = axios.CancelToken;
	const source = CancelToken.source();

	const config = useMemo(
		() => ({
			cancelToken: source.token,
		}),
		[source]
	);

	const fnWithCancel = (...args) => fn(config, ...args);

	const abort = (text = 'User aborted the the request') => {
		if (key && source) {
			source.cancel(text);
		}
	};

	const res = useSWR<Data, Error>(key, fnWithCancel, {
		...options,
		revalidateOnFocus: false,
	});

	return { ...res, abort, isLoading: !res.data && !res.error };
}
