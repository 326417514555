import {
	UploadDetailsDto,
	InitializeUploadDetails,
	ChunkDataDetails,
} from 'interfaces/api';

export const splitFiles = (
	details: UploadDetailsDto,
	data: InitializeUploadDetails[],
	chunkSize: number
) => {
	const chunks = [] as ChunkDataDetails[];
	details.uploadFileDetails.forEach(fileData => {
		const file = data.find(d => d.name === fileData.name).file;
		let offset = 0;

		while (offset < fileData.fileSize) {
			const from = offset;
			const to =
				offset + chunkSize > fileData.fileSize
					? fileData.fileSize - 1
					: offset + chunkSize - 1;
			const nextByte = to + 1;
			const chunk = file.slice(from, nextByte, file.type);

			chunks.push({
				uri: fileData.uri,
				bytesFrom: from,
				bytesTo: to,
				batchSize: chunk.size,
				data: chunk,
			});

			offset += chunkSize;
		}
	});

	return chunks;
};

export default splitFiles;
