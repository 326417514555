import { DuiColors } from '@dignisia/dui';
import { createGlobalStyle } from 'styled-components';

const AgGridGlobalStyles = createGlobalStyle`
    .ag-theme-material .ag-checkbox-input-wrapper.ag-checked:after {
        color: ${DuiColors.PRIMARY} !important;
    }
    .ag-theme-material .ag-toggle-button-input-wrapper {
        background-color: ${DuiColors.GREY_350} !important;
        border-color: ${DuiColors.GREY_350} !important;
    }
    .ag-theme-material .ag-toggle-button-input-wrapper:before{
        border-color:  ${DuiColors.GREY_350} !important;
    }
    .ag-theme-material .ag-toggle-button-input-wrapper.ag-checked {
        background-color: ${DuiColors.PRIMARY} !important;
        border-color: ${DuiColors.PRIMARY} !important;
    }
    .ag-tool-panel-wrapper {
        background-color: white !important;
    }
    .ag-theme-material .ag-row .ag-cell {
        font-size: 16px !important;
        line-height: 48px !important;
        font-weight: 300 !important!;
    }
    .ag-pivot-on .ag-header-group-cell {
        font-size: 16px;
        font-weight: 400;
        color: black !important;
    }
    .ag-header-row {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: black !important;
    }
    .ag-header-cell-label {
        font-size: 14px;
    }
    .ag-theme-material .ag-column-panel {
        border-left: 1px solid var(--ag-border-color, #e2e2e2);
    }
    .ag-theme-material .ag-paging-panel {
		color: rgba(0, 0, 0, 1) !important;
	}
    .ag-theme-material h3{
        font-weight: 400;
        color: ${DuiColors.GREY_750};
        padding: 20px 0 0px 22px;
        line-height: 1.6;
        letter-spacing: 0.0075em;
        margin-bottom: 20px;
    }
`;

export default AgGridGlobalStyles;
