import { lazy } from 'react';

const LandingPage = lazy(() => import('pages/landing'));
const ValidationPage = lazy(() => import('pages/validation'));
const ImportPortfolioPage = lazy(() => import('pages/import-portfolio'));
const DocumentationPage = lazy(() => import('pages/documentation'));
const UsersPage = lazy(() => import('pages/users'));
const DataSignOffListPage = lazy(
	() => import('pages/data-sign-off/data-sign-off-list')
);
const DataSignOffDetailsPage = lazy(
	() => import('pages/data-sign-off/data-sign-off-details')
);
const CodeMappingPage = lazy(
	() => import('pages/code-mapping/code-mapping-page')
);

interface RoutesProps {
	hide?: boolean;
	name: string;
	path: string;
	component: React.FC;
	exact: boolean;
}

export const fileRoutes: RoutesProps[] = [
	{
		hide: true,
		name: 'Landing',
		path: '/',
		component: LandingPage,
		exact: true,
	},
	{
		name: 'Validate files',
		path: '/validate',
		component: ValidationPage,
		exact: false,
	},
	{
		name: 'Import portfolio',
		path: '/import',
		component: ImportPortfolioPage,
		exact: false,
	},
	{
		name: 'Documentation',
		path: '/documentation',
		component: DocumentationPage,
		exact: false,
	},
	{
		name: 'Data sign off',
		path: '/data-sign-off',
		component: DataSignOffListPage,
		exact: true,
	},
	{
		name: 'Data sign off',
		path: '/data-sign-off/:id',
		component: DataSignOffDetailsPage,
		exact: false,
		hide: true,
	},
	{
		name: 'Code mapping (BETA)',
		path: '/code-mapping',
		component: CodeMappingPage,
		exact: true,
	},
	{
		name: 'Users',
		path: '/users',
		component: UsersPage,
		exact: false,
	},
];

export const homeRoute = fileRoutes[0]?.path;
export const validationRoute = homeRoute;
export const importRoute = fileRoutes[1]?.path;
