import { AsyncResponse, FileFormat } from 'interfaces/api';
import { UploadType } from 'types/upload-type';
import useApi from './use-api/use-api';

interface UseFileFormatProps extends AsyncResponse<FileFormat> {
	fileFormats: FileFormat[];
}

const useFileFormats = (
	dcaItemId: number,
	type?: UploadType
): UseFileFormatProps => {
	const typeParam = type ? `?type=${type}` : '';
	const { data, error, isValidating } = useApi<FileFormat[]>(
		dcaItemId ? `/client/${dcaItemId}/fileformats${typeParam}` : null
	);

	return {
		fileFormats: data,
		isLoading: isValidating,
		error,
	};
};

export default useFileFormats;
