import axios, { CancelToken, Canceler } from 'axios';

interface PendingRequests {
	uri: string;
	cancelToken: CancelToken;
	cancel: Canceler;
}

export let currentExecutingRequests: PendingRequests[] = [];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const requestInterceptor = req => {
	const originalRequest = req;
	const cancelTokenSource = axios.CancelToken.source();
	originalRequest.cancelToken = cancelTokenSource.token;
	originalRequest.cancel = cancelTokenSource.cancel;

	currentExecutingRequests.push({
		uri: req.url,
		cancelToken: originalRequest.token || cancelTokenSource.token,
		cancel: originalRequest.token || cancelTokenSource.cancel,
	});

	return originalRequest;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const responseInterceptor = response => {
	currentExecutingRequests.forEach((currentExecutingRequest, index) => {
		if (response.config.url.includes(currentExecutingRequest.uri)) {
			delete currentExecutingRequests[index];
		}
	});

	currentExecutingRequests = currentExecutingRequests.filter(
		request => request.uri !== response.config.url
	);

	return response;
};

export const cancelPendingRequest = (endpoint?: string): void => {
	currentExecutingRequests.forEach(currentPendingRequest => {
		if (currentPendingRequest.uri.includes(endpoint)) {
			currentPendingRequest.cancel();
		}
	});
	currentExecutingRequests = [];
};

export const cancelAllPendingRequests = (text?: string): void => {
	currentExecutingRequests.forEach(currentPendingRequest => {
		if (currentPendingRequest.uri.includes('widgets')) {
			currentPendingRequest.cancel(text);
		}
	});
	currentExecutingRequests = [];
};

export const cancelAllPendingCodeMappingRequests = (text?: string): void => {
	currentExecutingRequests.forEach(currentPendingRequest => {
		if (currentPendingRequest.uri.includes('code-mapping')) {
			currentPendingRequest.cancel(text);
		}
	});
	currentExecutingRequests = [];
};

export const request = axios.create();

request.interceptors.request.use(requestInterceptor);
request.interceptors.response.use(responseInterceptor);
