import {
	AsyncResponse,
	FileFormat,
	FileFormatDocumentation,
} from 'interfaces/api';
import useApi from './use-api/use-api';

interface UseFileFormatDocumentation extends AsyncResponse<FileFormat> {
	fileFormatDocumentation: FileFormatDocumentation;
}

const useFileFormatDocumentation = (dcaItemId): UseFileFormatDocumentation => {
	const { data, error, isValidating } = useApi<FileFormatDocumentation>(
		dcaItemId ? `/dca/${dcaItemId}/documentation` : null
	);

	return {
		fileFormatDocumentation: data,
		isLoading: isValidating,
		error,
	};
};

export default useFileFormatDocumentation;
