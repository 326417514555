import jwtDecode, { JwtPayload } from 'jwt-decode';

export const isTokenValid = (token: string): boolean => {
	const decoded = jwtDecode<JwtPayload>(token);

	if (Date.now() < decoded.exp * 1000) {
		if (Date.now() >= decoded.nbf * 1000) {
			return true;
		}
	}

	return false;
};

interface NameAndCompanyResponse extends JwtPayload {
	name?: string;
	company?: string;
}

interface DecodeResponse {
	userName?: string;
	company: string;
}

export const decodeNameAndCompany = (token: string): DecodeResponse => {
	const decoded = jwtDecode<NameAndCompanyResponse>(token);
	return {
		userName: decoded?.name,
		company: decoded?.company,
	};
};
