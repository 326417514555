import { useAuthProvider } from 'hooks';
import usePersistedState from 'hooks/use-persisted-state';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

const useSetOriginalPathRedirect = () => {
	const location = useLocation();
	const from = location.state;
	const [, setPersistedFrom] = usePersistedState(
		0,
		'login/backRedirect',
		null,
		'sessionStorage'
	);
	const [, setDoRedirect] = usePersistedState(
		0,
		'login/doRedirect',
		true,
		'sessionStorage'
	);

	useEffect(() => {
		if (from) {
			setPersistedFrom(from);
			setDoRedirect(true);
		}
	}, [from, setDoRedirect, setPersistedFrom]);
};

const useOriginalPathRedirect = () => {
	const location = useLocation();
	const history = useHistory();
	const { account, progress, isAuthenticated } = useAuthProvider();

	const from = location.state;
	const [persistedFrom, , clearPersistedFrom] = usePersistedState(
		0,
		'login/backRedirect',
		null,
		'sessionStorage'
	);
	const [doRedirect, setDoRedirect] = usePersistedState(
		0,
		'login/doRedirect',
		true,
		'sessionStorage'
	);

	useEffect(() => {
		if (isAuthenticated && progress !== 'login' && doRedirect) {
			const resolvedFrom = from ?? persistedFrom ?? { from: { pathname: '/' } };
			history.replace(resolvedFrom.from.pathname);
			setDoRedirect(false);
			clearPersistedFrom();
		}
	}, [
		history,
		isAuthenticated,
		account,
		location,
		progress,
		from,
		persistedFrom,
		clearPersistedFrom,
		doRedirect,
		setDoRedirect,
	]);
};

export { useSetOriginalPathRedirect, useOriginalPathRedirect };
