import { Configuration } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';
import config from 'api/config';

export const MSAL_SCOPES = ['openid profile email'];

export const msalConfiguration: Configuration = {
	auth: {
		clientId: config?.msalClientId || '',
		authority: `https://login.microsoftonline.com/${config?.msalTenantId}`,
		redirectUri: config?.logoutRedirectUrl,
		navigateToLoginRequestUrl: false,
	},
	system: {
		tokenRenewalOffsetSeconds: 1200,
	},
};

export const msalInstance = new PublicClientApplication(msalConfiguration);
