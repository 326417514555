import { useClients, useDcaItems } from 'hooks/api';
import usePersistedState from 'hooks/use-persisted-state';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { ClientSettingsContext } from './client-settings-context';

interface Props {
	children?: ReactNode;
}

const ClientSettingsProvider: React.FC<Props> = ({ children }) => {
	const { clients, selectedClientId, selectedClient, updateCurrentClient } =
		useClients();

	const setSelectedClientId = async (clientId: number) => {
		await updateCurrentClient(clientId);
		revalidateDcaItems();
		const currentURL = window.location.href;
		window.location.href = currentURL;
	};

	const { dcaItems, revalidate: revalidateDcaItems } = useDcaItems();
	const [selectedDcaItemId, setSelectedDcaItemId] = usePersistedState<
		number | null
	>(selectedClientId, 'SelectedDcaItemId', null, 'localStorage');
	const selectedDcaItem = useMemo(() => {
		return dcaItems?.find(dcaItem => dcaItem.id === selectedDcaItemId);
	}, [dcaItems, selectedDcaItemId]);

	useEffect(() => {
		if (
			clients.length &&
			selectedClientId &&
			dcaItems?.length &&
			!selectedDcaItemId
		) {
			setSelectedDcaItemId(dcaItems[0].id);
		}
	}, [
		clients.length,
		dcaItems,
		selectedClientId,
		selectedDcaItemId,
		setSelectedDcaItemId,
	]);

	return (
		<ClientSettingsContext.Provider
			value={{
				selectedClient,
				selectedDcaItem,
				selectedClientId,
				selectedDcaItemId,
				dataProviderGroupId: selectedDcaItem?.dataProviderGroupId,
				countryCode: selectedDcaItem?.countryCode,
				availableClients: clients,
				avaliableDcaItems: dcaItems,
				setSelectedClientId,
				setSelectedDcaItemId,
			}}
		>
			{children}
		</ClientSettingsContext.Provider>
	);
};

export default ClientSettingsProvider;
