import useAuthProvider from 'hooks/use-auth-provider';
import { AsyncResponse, DcaItem } from 'interfaces/api';
import useApi from './use-api/use-api';

interface UseDcaItemProps extends AsyncResponse<DcaItem[]> {
	dcaItems: DcaItem[];
}

const useDcaItems = (): UseDcaItemProps => {
	const { isAuthenticated } = useAuthProvider();

	const { data, error, isValidating, revalidate } = useApi<DcaItem[]>(
		isAuthenticated ? `/client/dcaitems` : null
	);

	return {
		dcaItems: data || [],
		error,
		isLoading: isValidating,
		revalidate,
	};
};

export default useDcaItems;
