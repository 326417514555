import React from 'react';
import { Redirect } from 'react-router';
import { homeRoute } from 'routes';

const BaseRedirect = () => {
	// This page will only ensure that a redirect from '/' to the value of homeRoute is happening.
	// This is to avoid unnecessary redirectUrls for the AD application since it is shared between many apps.
	return <Redirect to={homeRoute} />;
};

export default BaseRedirect;
