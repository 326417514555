import { useState, useEffect } from 'react';
import { MSAL_SCOPES } from '../utils/auth';
import { useMsal, useIsAuthenticated, useAccount } from '@azure/msal-react';
import {
	AccountInfo,
	AuthenticationResult,
	EventMessage,
	EventType,
	InteractionStatus,
} from '@azure/msal-browser';

interface UseAuthProvider {
	account: AccountInfo;
	isAuthenticated?: boolean;
	error: string;
	progress: InteractionStatus;
	onSignIn: () => void;
	onSignOut: () => void;
}

const useAuthProvider = (): UseAuthProvider => {
	const [error] = useState<string>(null);

	const { instance, inProgress } = useMsal();
	const account = useAccount();
	const isAuthenticated = useIsAuthenticated();

	const [callbackId, setCallbackId] = useState<string>();

	useEffect(() => {
		if (callbackId) {
			instance.removeEventCallback(callbackId);
		}

		const callbackIdNew = instance.addEventCallback((event: EventMessage) => {
			if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
				const payload = event.payload as AuthenticationResult;
				const account = payload.account;
				instance.setActiveAccount(account);
			}
			if (
				event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
				event.payload
			) {
				const payload = event.payload as AuthenticationResult;
				const account = payload.account;
				instance.setActiveAccount(account);
			}
		});
		setCallbackId(callbackIdNew);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, instance]);

	const handleSignIn = () => {
		return instance.loginRedirect({
			scopes: MSAL_SCOPES,
			prompt: 'select_account',
		});
	};

	const handleSignOut = () => {
		return instance.logoutRedirect();
	};

	return {
		account,
		error,
		isAuthenticated,
		progress: inProgress,
		onSignIn: () => handleSignIn(),
		onSignOut: () => handleSignOut(),
	};
};

export default useAuthProvider;
